<template>
    <div class="menu-component">
        <nested-draggable :pages="this.myPages"></nested-draggable>
        <a  class="menu-submit button is-light" href="/admin/page/create">Создать страницу</a>
        <button v-on:click.prevent="sendForm" :disabled="loading || disabled" :class="{ 'is-loading': loading }" class="menu-submit button is-primary"><span class="icon is-small"><i class="fas fa-check"></i></span><span>Сохранить</span></button>
            <div v-if="message" class="notification is-success menu-message">
                <button type="button" class="delete" data-dismiss="alert" v-on:click="hideFlash">×</button>
                <strong>{{ message }}</strong>
            </div>
    </div>
</template>

<script>
  import NestedDraggable from '../components/NestedDraggable.vue';

  export default {
    props: ['pages'],
    mounted() {
       console.log(this.urlPrefix);
    },
    components:{
      NestedDraggable,
    },
    data() {
      return {
        myPages: this.pages,
        loading: false,
        disabled: false,
        message: '',
        urlPrefix: window.location.protocol + '/' + window.location.host + '/admin',
      }
    },
    methods: {
      sendForm(){
        this.loading = true;
        console.log('pages to be sent on the server = ' + this.pages);
        axios.patch('/admin/menu/order', {
          'pages': this.pages,
        })
          .then((response) => {
            if(response) {
              console.log(response);
              this.loading = false;
              this.message = 'Сохранено успешно';
            }
          })
          .catch((error) => {
              console.log(error);
            this.loading = false;
            this.message = 'Что-то пошло не так...';
          });
      },
      hideFlash: function(e){
        this.message = '';
      }
    },


  }
</script>
