<template>
    <div>
        <editor apiKey="j4xseayz4as1w5l3iy17gj8o6gb2b0043727mpaq6pbtx283" :init="{ height: 500, menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table directionality',
        'emoticons template paste  textpattern image'
         ],
         toolbar:[
           'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media'],
         relative_urls: false,
          file_picker_callback : this.filemanager_callback,
          setup: setData,

       }" v-model="editorData" @onChange="changed"></editor>
        <div class="editor_submit_wrapper">
            <div class="non_saved" :class="{ 'is-warning': warning }"><span><i class="fas fa-exclamation-triangle"></i></span>Не сохранено</div>
            <button v-on:click.prevent="saveContent" :disabled="loading || disabled" :class="{ 'is-loading': loading }" class="button is-primary"><span class="icon is-small"><i class="fas fa-check"></i></span><span>Сохранить</span></button>
        </div>
    </div>

</template>

<script>
   import Editor from '@tinymce/tinymce-vue'

  export default {
      props: ['data'],
      components: {
          'editor': Editor,
      },

    data() {
      return {
          loading: false,
          disabled: false,
          editorData: '',
          content : this.data.content,
          language: this.data.lang,
          post_data: this.data,
          warning: false,
      };
    },

    mounted(){
          this.callback = this.filemanager_callback;
    },
    methods: {
        filemanager_callback(callback, value, meta) {
            let x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
            let y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight

            tinymce.activeEditor.windowManager.openUrl({
                url : '/file-manager/tinymce5',
                title : 'Laravel File manager',
                width : x * 0.8,
                height : y * 0.8,
                onMessage: (api, message) => {
                    callback(message.content, { text: message.text })
                }
            })
        },
        setData(editor){
                var content = this.content;
                if(content) {
                    editor.on('init', (e) => {
                        editor.setContent(content);
                    });
                }
        },

      saveContent(){
        axios.patch('/admin/' + this.data.url + this.post_data.id, {
            'lang': this.post_data.lang,
            'id': this.post_data.id,
            'content': this.editorData,
        })
          .then((response) => {
            if(response) {
              this.loading = false;
              this.message = 'Сохранено успешно';
              this.warning = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.message = 'Что-то пошло не так...';
          });
      },
        changed(){
            this.warning = true;
        }
    },
  }
</script>
