<template>
     <date-picker v-model="currentDate" valueType="format"></date-picker>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
export default {
    name: 'date-picker-component',
    components:{DatePicker},
    props:{
        date: [String]
    },
    data(){
        return{
            currentDate : this.date
        }
    }
}
</script>

<style>
.mx-icon-calendar{
    display: none;
}
.mx-input-wrapper input{
    border: none;
    box-shadow: none;
    background-color: transparent;
}
.mx-input-wrapper .mx-icon-clear {
    display: none !important;
}
</style>