<template>
    <div class="m-video-druggable">
        <div v-for="element in videos" :key="element[0]">
            <div class="card">
            <div  class="cat_headline card-header">
                <div v-text="element[0]"></div>
                <a href="#" class="card-header-icon show-options" aria-label="more options" @click="showOptions">
                    <span class="icon"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
                </a>
                <a href="#" class="card-header-icon hide-options" aria-label="more options" @click="hideOptions">
                    <span class="icon"><i class="fas fa-angle-up" aria-hidden="true"></i></span>
                </a>
            </div>
                <div class="card-content">
                <draggable v-model="element[1]" class="container" v-bind="dragOptions" @group="element[0]" @start="drag = true" @end="drag = false" @change="rebuildOrder">
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="video_wrapper">
                            <div v-for="el in element[1]" :key="el.id" class="video_block">

                                                    <div v-if="el.is_promo == 0" class="video">
                                                        <div v-text="el.order_in_cat"></div>
                                                        <iframe width="300" height="150"  :src='"https://www.youtube.com/embed/"+ el.youtube_id' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                                    </div>
                                                    <div v-if="el.is_promo == 1" class="video_promo">
                                                        <div v-text="el.order_in_cat"></div>
                                                        <iframe width="300" height="150"  :src='"https://www.youtube.com/embed/"+ el.youtube_id' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                                    </div>
                            </div>
                        </transition-group>
                </draggable>
                </div>
            </div>
    </div>
        <button v-on:click.prevent="sendForm" :disabled="loading" class="button is-primary">Сохранить <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        props: {
            list: [Object,null]
        },
        data() {
            return {
                videos: Object.entries(this.list),
                drag: false,
                loading: false,
                message: '',
            }
        },
        components: {
            draggable
        },
        mounted() {
          console.log(this.videos);
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            // sort() {
            //     this.videos = this.videos.sort((a, b) => a.order - b.order);
            // },
            rebuildOrder(e){
                this.videos.forEach(el => {
                        el.forEach(subel => {
                           if (typeof (subel) != "string"){
                               subel.forEach(video => {
                                   video.order_in_cat = subel.indexOf(video);
                               });
                           }
                        });
                    }
                );
            },

            sendForm(){
               console.log(this.videos);
                this.loading = true;
                axios.post('/admin/video/catorder', {
                    'videos': this.videos,
                })
                    .then((response) => {
                        console.log(response);
                        if(response) {
                            this.loading = false;
                            this.message = 'Updated successfully';
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.message = 'Something went wrong!';
                    });
            },
            showOptions(e){
                let show = e.target.closest('.show-options');
                let hide = show.nextElementSibling;
                show.style.display = "none";
                hide.style.display = "flex";
                e.target.closest('.card-header').nextElementSibling.style.display = "flex";
            },
            hideOptions(e){
                let hide= e.target.closest('.hide-options');
                let show = hide.previousElementSibling;
                show.style.display = "flex";
                hide.style.display = "none";
                e.target.closest('.card-header').nextElementSibling.style.display = "none";
            }
        },
    }
</script>
