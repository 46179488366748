import { data } from "jquery";
import Videos from "../Videos";
const VIDEOS_SECTION_SIZE = 6
export default{
    namespaced:true,
    state: () => ({ 
        data: {},
     }),
  mutations: { 
    setId(state, data) {
        if (!state.data.hasOwnProperty(data.video_id)){
                state.data[data.video_id] = new Videos(VIDEOS_SECTION_SIZE)           
        }
        state.data[data.video_id].id = Number(data.id)
        state.data = Object.assign(Object.create(state.data), state.data)
    },
    setSectionId(state, data){
        if (!state.data.hasOwnProperty(data.video_id)){
            if (!data.offset){
                state.data[data.video_id] = new Videos(VIDEOS_SECTION_SIZE)
            }else{

                state.data[data.video_id] = new Videos(data.offset)
            }
        }
        state.data[data.video_id].section_id = Number(data.section)
        state.data = Object.assign(Object.create(state.data), state.data)
    },
    addActiveTag(state, id) { 
        const vids = state.data
        for (let i in vids) {
                vids[i].addActiveTag(id)
        }
        state.data = Object.assign(Object.create(state.data), state.data)
    },
    addVideos(state, data) {
        if (!state.data[data.video_id]){
            state.data[data.video_id] = new Videos(VIDEOS_SECTION_SIZE)
        }
        state.data[data.video_id].videos = data.data
        state.data = Object.assign(Object.create(state.data), state.data)
    },

    removeVideosByTag(state, id) {
       for (let i in state.data) {
            state.data[i].removeVideosByTag(id)
        }
        state.data = Object.assign(Object.create(state.data), state.data)
    },
    addTagVideos(state, data) {
    const infoForTags = data.map(vid => {
        return {
            tagInfo:{
                allVideosSent: vid.allVideosSent,
                videos: vid.videos,
                id: vid.id,
                section_id: vid.section_id
            }
        }
    })
        for (let i in infoForTags) {
            for (let j in state.data){                 
                if (state.data[j].section_id){
                    if (state.data[j].section_id === infoForTags[i].tagInfo.section_id){
                        state.data[j].tagVideos = infoForTags[i]                        
                    }                    
                }else{
                    
                    state.data[j].tagVideos = infoForTags[i]
                }

                     
            }
        }
        state.data = Object.assign(Object.create(state.data), state.data)
    },
    addTagVideosToSection (state, data) {        
        for (let i in state.data){
            if (state.data[i].section_id === data.section_id){
                state.data[i].tagVideos = {
                    tagInfo:{
                    allVideosSent: data.allVideosSent,
                    videos: data.videos,
                    id: data.id,
                    section_id: data.section_id
                }}
            }
        }
        state.data = Object.assign(Object.create(state.data), state.data)

    }

   },
  actions: { 
    get: (context, data) => {
        if (!context.state.data[data.video_id]){
            context.commit('setId', data)
            context.commit('setSectionId', data)
        }else if (context.state.data[data.video_id].id !== data.id){
            context.commit('setId', data)
        }
        axios.get('/videos', {
            params: {
                id: data.id,
                offset: context.state.data[data.video_id].offset,
                quantity: context.state.data[data.video_id].quantity,
                section: data.section
            }
        }).then((res) => {
            context.commit('addVideos', {video_id: data.video_id, data: res.data})
        }).catch(err => {
            console.log(err)
        })
    },
    getByTag: (context, id) =>{
        const formVideosTagRequest = (vids) => {
            const result = []
            for (let i in vids) {
                    const free_id = vids[Number(i)].getAvaibleTagId
                    if (free_id){
                        result.push({
                            id: free_id,
                            parent_id: vids[Number(i)].id,
                            offset: vids[Number(i)].offset,
                            quantity: vids[Number(i)].quantity,
                            section_id:  vids[Number(i)].section_id
                        })
                    }else{
                        result.push({
                            id,
                            parent_id: vids[Number(i)].id,
                            offset: vids[Number(i)].offset,
                            quantity: vids[Number(i)].quantity,
                            section_id:  vids[Number(i)].section_id
                        })

                    }
            }
            return result
        }
        context.commit('addActiveTag', Number(id))
        // context.commit('addActiveTag', Number(id))
        const req_data = formVideosTagRequest(context.state.data)
        axios.get('/videos/filter-by-tag', {
            params: {data: req_data}
        }).then((res) => {
            context.commit('addTagVideos', res.data)
        }).catch(err => {
            console.log(err)
        })
    },
    loadByTag: (context,data) => {
        const free_id = context.state.data[data.video_id].getAvaibleTagId
        if (free_id){
            const req_data = {
                id: free_id, 
                parent_id: context.state.data[data.video_id].id,
                offset: context.state.data[data.video_id].offset,
                quantity: context.state.data[data.video_id].quantity,
                section_id:  context.state.data[data.video_id].section_id 
            }
            axios.get('/videos/load-by-tag', {
                params: {data: req_data}
            }).then((res) => {
                context.commit('addTagVideosToSection', res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }
   },
  getters: {  }
}