<template>
<div class="wrapper">

    <button class="reset" v-if="isActive" @click="handleClick">Сбросить результаты поиска</button>
</div>
</template>

<script>
    export default {
        name: 'blogs-search-reset',
        props: {
        },
        methods:{
            handleClick(e){
                this.$store.dispatch('resetHandler')
            }
        },
        computed:{
            isActive(){
                return this.$store.getters.isResetActive
            }
        }
    }
</script>
<style scoped>
    .wrapper{
        display: flex;
        justify-content: center;
    }
    button.reset{
        background: none;

        border: none;
        width: 200px;
        padding: 10px;
        background-color: #92c300;
        border-radius: 20px;
        color: white;
    }
    button.reset:hover{
        cursor: pointer;
    }
</style>
