import CopyInterface from './CopyInterface'
class BlogsPaginator {
    constructor(perPage, currentPage = 1, paginatorLinksQuantity = 4) {
        this._perPage = perPage
        this._blogs = []
        this._currentPage = currentPage
        this._pagesQuan = 0
        this._currentData = []
        this._pagesLinks = []
        if (paginatorLinksQuantity < 3) throw new Error('BlogsPaginator:paginatorLinksQuantityis too small!')
        this._paginatorLinksQuantity = paginatorLinksQuantity
    }
    isEmpty() {
        if (this._blogs.length == 0) return true
        return false
    }
    hasBlogsChunk(id) {
        if (!this._blogs[id]) return false
        else if (this._blogs[id].length !== 0) return true
        return false
    }
    get length() {
        return this._blogs.length
    }
    updatePagesLinks() {
        // ПЕРВАЯ ССЫЛКА ВСЕГДА РАВНЕ НОМЕРУ АКТИВНОЙ СТРАНИЦЫ (ЕСЛИ НЕ КОНЕЦ ВЫВОДА СТРАНИЦ)
        if (this._pagesQuan >= 2) {
            this._pagesLinks = []
            let offset = 0
            let i = this._currentPage
            if (i + this._paginatorLinksQuantity > this._pagesQuan) {
                offset = this._pagesQuan - this._paginatorLinksQuantity + 1
                if (offset <= 0) offset = 1
                i = offset
            }
            for (let j = 0; j < this._paginatorLinksQuantity && j < this._pagesQuan; j++) {
                if (j + 2 === this._paginatorLinksQuantity) {
                    if (i + 1 < this._pagesQuan) {
                        this._pagesLinks.push('hideLink')
                    } else {
                        this._pagesLinks.push(i++)
                    }
                } else if (j + 1 === this._paginatorLinksQuantity) {
                    this._pagesLinks.push(this._pagesQuan)
                    break;
                } else this._pagesLinks.push(i++)

                if (i > this._pagesQuan) break;
            }
        }
    }
    set blogsOveralLength(l) {
        this._pagesQuan = Math.ceil(l / this._perPage)
        for (let i = 0; i < this._pagesQuan; i++) {
            this._blogs[i] = []
        }
        this.updatePagesLinks()
    }
    addBlogs(blogs, page = 0) {
        this._blogs[page] = blogs
        this._currentPage = page + 1
        this.updatePagesLinks()
    }
    addBlogItems(blogs, page = 0) {
        if (!this._blogs[page]) {
            this._blogs[page] = []
        } else {
            while (this._blogs[page].length >= this._perPage) { page++ }
        }
        const capacity = this._perPage - this._blogs[page].length
        for (let i = 0; i < blogs.length; i++) {
            if (i >= capacity) {
                this._blogs[++page] = []
            }
            this._blogs[page].push(blogs[i])
        }
        this._currentPage = page + 1
        this.updatePagesLinks()
    }
    get pagesLinks() {
        return this._pagesLinks
    }
    remove(id) {
        let index;
        this._currentPage = 1
        this._blogs.forEach((blog, i) => {
            blog.forEach((blog_local, i_local) => {
                if (blog_local.id === id) {
                    delete this._blogs[i][i_local]
                }
            })
        })
        const result = []
        let counter = 0
        this._blogs.forEach((blog, i) => {
            result[counter] = []
            blog.forEach((blog_local, i_local) => {
                result[counter].push(blog_local)
                if (result[counter].length >= this._perPage) {
                    result[++counter] = []
                }
            })
        })

        this._blogs = Object.assign([], result)
    }
    pageId(id) {
        if (id <= 0 || id > this._pagesQuan) {
            throw new Error('Blogs.page: Attempt to get non-existing page!')
        }
        this._currentPage = id
        this.updatePagesLinks()
    }
    _getPage(currentPage) {
        return this._blogs[currentPage - 1]
    }
    page(id) {
        if (id <= 0 || id > this._pagesQuan) {
            throw new Error('Blogs.page: Attempt to get non-existing page!')
        }
        this._currentPage = id
        this.updatePagesLinks()
        return this._getPage(this._currentPage)
    }
    get currentPage() {
        return this._getPage(this._currentPage)
    }
    get currentPageNumber() {
        return this._currentPage
    }
    get nextPage() {
        if (this._currentPage + 1 <= this._pagesQuan) {
            this._currentPage++;
            return this._getPage(this._currentPage)
        } else {
            return this._getPage(this._currentPage)
        }
    }
    get prevPage() {
        if (this._currentPage - 1 > 0) {
            this._currentPage--;
            return this._getPage(this._currentPage)
        } else {
            return this._getPage(this._currentPage)
        }
    }
    get firstPage() {
        this._currentPage = 1
        return this._getPage(this._currentPage)
    }
    get lastPage() {
        this._currentPage = this._pagesQuan
        return this._getPage(this._currentPage)
    }
    get pagesQuantity() {
        return this._pagesQuan
    }
    get perPage() {
        return this._perPage
    }
    copy(obj) {
        return Object.assign(Object.create(this), obj)
    }
    clear() {
        this._blogs = []
        this._currentPage = 1
        this._pagesQuan = 0
        this._pagesLinks = []
    }

}
export default BlogsPaginator