<template>
    <div class="container">
        <div v-for="(element, index) in pages">
            <div class="nested">
<!--                <div class="page-bar"> {{ element.parent_path }}</div>-->
                <div v-for="(sub, index) in element.sub">
                    <div class="">{{ sub.path }}</div>
                </div>
            </div>
        </div>
        <div class="pages-container">
            <div v-for="(element, index) in pages" class="box each-page">
                <div class="title">{{ element.parent_title }}</div>
                <hr>
                <draggable group="sub_pages" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="rebuildOrder" :list="element.sub">
                        <div v-for="(sub, index) in element.sub" class="card" :key="sub.id">
            <!--                <div class="fuck">{{ sub.title }}</div>-->
                            <header class="card-header">
                                <p class="card-header-title"><a :href="urlPrefix + '/page/' +  sub.id  + '/edit'">{{ sub.title }}</a></p>
                                <a href="#" class="card-header-icon show-options" aria-label="more options" v-on:click="showOptions">
                                  <span class="icon">
                                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                                  </span>
                                </a>
                                <a href="#" class="card-header-icon hide-options" aria-label="more options" v-on:click="hideOptions">
                                  <span class="icon">
                                    <i class="fas fa-angle-up" aria-hidden="true"></i>
                                  </span>
                                </a>
                            </header>
                            <footer class="card-footer">
                                <a href="#" class="card-footer-item">Edit</a>
                                <a href="#" class="card-footer-item red">Delete</a>
                            </footer>
                        </div>
                </draggable>
            </div>
        </div>
        <button v-on:click.prevent="sendForm" :disabled="loading || disabled" class="button is-primary">Update <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
        <div>{{ same }}</div>
    </div>
</template>

<script>
  import draggable from 'vuedraggable';

  export default {
    props: ['list'],
    mounted(){
       console.log(this.list);
    },
    data() {
      return {
        pages: this.list,
        drag: false,
        loading: false,
        message: '',
        same: '',
        disabled: false,
        active: false,
        urlPrefix: window.location.protocol + '//' + window.location.hostname + '/admin',
      }
    },

    components: {
      draggable
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "sub_pages",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    methods: {
      sort() {
        this.pages = this.pages.sort((a, b) => a.order - b.order);
      },
      rebuildOrder(e){
        this.same = '';
        this.disabled = false;
        this.pages.forEach((e) => {
          e.sub.forEach((el) => {
              el.order = (e.sub.indexOf(el))
              el.parent_id = (e.parent);
              el.path = e.parent_path + '/' + el.title;
              if(el.parent_id == el.id){
                this.same = 'Element ' + el.title + ' cannot contain itself!';
                this.disabled = true;
              }
          });

        });
      },
      sendForm(){
        this.loading = true;
        axios.patch('/admin/menu/childOrder', {
          'pages': this.pages,
        })
          .then((response) => {
            if(response) {
              console.log(response);
              this.loading = false;
              this.message = 'Updated successfully';
            }
          })
          .catch((error) => {
            this.loading = false;
            this.message = 'Something went wrong!';
          });
      },
      showOptions(e){
        let show = e.target.closest('.show-options');
        let hide = show.nextElementSibling;
        show.style.display = "none";
        hide.style.display = "flex";
        e.target.closest('.card-header').nextElementSibling.style.display = "flex";
      },
      hideOptions(e){
        let hide= e.target.closest('.hide-options');
        let show = hide.previousElementSibling;
        show.style.display = "flex";
        hide.style.display = "none";
        e.target.closest('.card-header').nextElementSibling.style.display = "none";
      }

    },
  }
</script>
<style>
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
</style>
