<template>

    <div v-if="myVideos.length > 0">
        <div class="col-12 video-items__bg">
            <div class="row video-items__row load-more-row">
                <div v-for="(video, i) in myVideos" :key="i"
                    :class="{'col-12': true, 'col-md-8': video.is_promo ? true : false, 'col-md-4':video.is_promo ? false : true , 'video-items__col fade': true}">
                    <div class="video-items__item">
                        <div class="video-items__video">
                            <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + video.youtube_id"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="">
                            </iframe>
                        </div>
                        <span v-if="showDateOfCreation" class="date">{{video.created_at.split('T')[0]}}</span>
                        <span class="title">{{ locale == 'uk' ? video.title_ua : video.title_ru}}</span>
                        <span class="desk">{{ locale == 'uk' ? video.description_ua : video.description_ru}}</span>
                    </div>
                </div>
            </div>
            <div v-on:click="loadMoreVideos" v-if="!allVideosSent" class="col-12 text-center">
                <div class="load-more">{{ locale == 'uk' ? 'Показати більше': 'Показать больше'}}
                    <b>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                            y="0px" width="98px" height="98px" viewBox="0 0 98 98" enable-background="new 0 0 98 98"
                            xml:space="preserve">
                            <g>
                                <path clip-path="url(#SVGID_2_)" fill="none" stroke="#000000" stroke-width="3"
                                    stroke-miterlimit="10"
                                    d="M93.9,33.4   C92.2,28.6,89.8,24.1,86.6,20c-3.1-4-6.8-7.5-11-10.3C72.2,7.4,68.5,5.6,64.7,4.2c-1.601-0.6-3.4,0.4-3.8,2   C60.5,7.7,61.3,9.3,62.8,9.8C79.3,15.6,90.6,31.3,90.6,49C90.6,71.9,72,90.6,49,90.6C26,90.6,7.4,71.9,7.4,49   c0-16.1,9.399-30.7,23.8-37.5v10.8c0,1.6,1.3,3,3,3c1.599,0,3-1.3,3-3V4.5c0-1.6-1.3-3-3-3H16.299c-1.599,0-3,1.3-3,3   c0,1.6,1.301,3,3,3h9.801c-2,1.1-3.801,2.3-5.7,3.7c-3.8,2.9-7.2,6.3-10,10.2c-5.8,8-8.9,17.6-8.9,27.6c0,6.4,1.299,12.6,3.7,18.5   c2.4,5.7,5.8,10.7,10.2,15.1c4.399,4.4,9.399,7.801,15.1,10.2c5.9,2.5,12.1,3.7,18.5,3.7c6.4,0,12.6-1.3,18.5-3.7   C73.2,90.4,78.2,87,82.6,82.6C87,78.2,90.4,73.2,92.8,67.5c2.5-5.9,3.7-12.1,3.7-18.5C96.5,43.7,95.6,38.4,93.9,33.4z" />
                            </g>
                        </svg>
                    </b>
                </div>
            </div>
            <div v-if="error">{{error}}</div>
        </div>
    </div>
</template>
<script>
    import * as axios from 'axios'
    export default {
        name: 'dynamic-videos',
        props: {
            page_id: Number,
            video_id: Number,
            showdate: Number,
            query: String,
            section: {
                type: Number,
                required: false
            },
            locale: {
                type: String,
                required: true
            },
            offset:{
                required: false,
                type: Number
            }
        },
        data() {
            return {
                error: '',
                id: this.page_id ?? null,
                videoId: this.video_id,
                showDateOfCreation: this.showdate ?? false,
                loc: this.locale
            }
        },
        created() {            
                this.$store.dispatch(this.query, {
                video_id: this.videoId,
                id: this.id,
                section: this.section,
                offset: this.offset
            })
            
            
        },
        computed: {
            allVideosSent() {
                if (!this.onTagVideos)
                    return this.$store.state.data.data[this.videoId].allVideosSent
                else return this.allTagsVideosSent === true
            },
            allTagsVideosSent() {
                return this.$store.state.data.data[this.videoId].isAllTagsVideosSent === true
            },
            onTagVideos() {
                return this.$store.state.data.data[this.videoId].currentlyOnTagVideos
            },
            overallQuantity() {
                return this.$store.state.data.data[this.videoId].quantity
            },
            myVideos() {
                return this.$store.state.data.data[this.videoId].videos
            },
            tagsData() {
                return this.$store.state.data.data[this.videoId].tagsData
            },
            allVideos() {
                return this.$store.state.data.data[this.videoId].allVideos
            },
            test() {
                return this.$store.state.test
            },
            getQuery() {
                return this.$store.state.getQuery
            },
            dynamicId() {
                return this.$store.state.data.data[this.videoId].currentlyOnTagVideos ? this.$store.state.data.data[this
                    .videoId].tagId : this.$store.state.data.data[this.videoId].id
            },
            initialIds() {
                return this.$store.state.data.data[this.videoId].initialVideosIds
            }
        },
        methods: {
            loadMoreVideos(e) {
                //if (this.$store.state.data.data[this.videoId].currentlyOnTagVideos === true)
                    /* this.$store.dispatch('filterVideosByTag', {
                        video_id: this.videoId,
                        id: this.dynamicId,
                        section: this.section,
                        offset: this.offset
                    }); */
                // else 
                this.$store.dispatch('getVideos', {
                    video_id: this.videoId,
                    id: this.id,
                    section: this.section
                })
            }
        }
    }

</script>
<style>
    .date {
        display: block;
        position: relative;
        font-size: 16px;
        height: 20px;
        line-height: 23px;
        padding-left: 30px;
        color: #000;
        opacity: .5;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        filter: alpha(opacity=50);
        margin-top: 10px;
        //margin-bottom: -6px;
    }

    .date::before {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 0;
        color: #000;
        width: 20px;
        height: 20px;
        font-size: 20px;
        content: '\e90c';
    }

</style>
