import BlogsPaginator from "../BlogsPaginator";
import TagsData from "../tagsData";

export default{
    state: () => ({ 
        blogs: new TagsData()
     }),
  mutations: {  },
  actions: {  },
  getters: {  }
}