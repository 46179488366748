<template>
    <div>
        <draggable class='m-cat-list' v-model="categories" v-bind="dragOptions" @change="rebuildOrder">
            <a v-for="(cat, i) in categories" :key="i" class="item" :href="'page/' + cat.id + '/edit'">
                            <div class="num">
                                0{{ cat.order + 1 }}
                            </div>
                            <div class="img">
                                <img :src="cat.cat_preview">
                            </div>
                            <div class="text">
                                {{ cat.title }}
                            </div>
            </a>
        </draggable>
        <div>{{message}}</div>
    </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import * as axios from 'axios'
    export default {
        name: 'categories',
        components: {draggable},
        props: {
            cats: Array
        },
        data(){
            return {
                msg: '',
                catsProp: this.cats
            }
        },
        computed: {
            dragOptions() {
                    return {
                    animation: 200,
                    group: "categories",
                    disabled: false,
                    ghostClass: "ghost"
                }
            },
            message(){
                return this.msg
            },
            categories:{
                get () {
                    return this.catsProp
                },
                set(cats){
                    this.catsProp = [...cats]
                }
            }
        },
        methods:{
            rebuildOrder(e){
                console.log('rebuild')
                this.catsProp.forEach(el => el.order = (this.catsProp.indexOf(el)));
                this.sendForm(this.catsProp);
            },
            sendForm(cats){
                axios.patch('/admin/categories/order', {
                'categories': cats,
        })
          .then((response) => {
            if(response.data) {
              this.catsProp = [...response.data];
              //this.msg = 'Updated successfully';
            }
          })
          .catch((error) => {
                alert("Ошибка: Не удалось обновить категории при перетаскивании!")
            });
            }
        }
    }
</script>
<style scoped>
</style>
