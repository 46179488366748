var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{attrs:{"apiKey":"j4xseayz4as1w5l3iy17gj8o6gb2b0043727mpaq6pbtx283","init":{ height: 500, menubar: false,
     plugins: [
       'advlist autolink lists link image charmap print preview hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars code fullscreen',
    'insertdatetime media nonbreaking save table directionality',
    'emoticons template paste  textpattern image'
     ],
     toolbar:[
       'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media'],
     relative_urls: false,
      file_picker_callback : this.filemanager_callback,
      setup: _vm.setData,

   }},on:{"onChange":_vm.changed},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}}),_vm._v(" "),_c('div',{staticClass:"editor_submit_wrapper"},[_c('div',{staticClass:"non_saved",class:{ 'is-warning': _vm.warning }},[_vm._m(0),_vm._v("Не сохранено")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.loading },attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.saveContent($event)}}},[_vm._m(1),_c('span',[_vm._v("Сохранить")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-exclamation-triangle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-check"})])}]

export { render, staticRenderFns }