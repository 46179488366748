<template>
    <div class="m-del-button">
        <div @click="unlock" :class="{ 'is-active': locked }" class="lock"><i class="fas fa-lock" ></i></div>
        <div @click="lock" :class="{ 'is-active': unlocked }" class="lock"><i class="fas fa-lock-open"></i></div>
        <input :disabled="disabled" class="button is-danger" type="submit" value="Удалить">
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tag: 'damn',
                disabled: true,
                locked: true,
                unlocked: false,

            };
        },
        methods:{
            saveTags(obj){

            },
            lock(){
                this.locked = true;
                this.unlocked = false;
                this.disabled = true;
            },
            unlock(){
                this.locked = false;
                this.unlocked = true;
                this.disabled = false;
            }

        }
    };
</script>
