/**
 * First we will load all of state.data project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
require('./jquery-2.2.0.min');
require('./new-owl/owl.carousel');
require('./new-owl/owl.autoplay');
require('./new-owl/owl.navigation');
require('./new-owl/owl.support');
require('./new-owl/owl.autoheight');
// require('@ckeditor/ckeditor5-build-classic/build/ckeditor');
// require('@ckeditor/ckeditor5-vue/dist/ckeditor');
window.Vue = require('vue');
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
require('./modules/_burger_menu');
require('./modules/_script-new');
require('./modules/_sliders-new');
//require('./modules/_file_manager');
// import Vuex from 'vuex';
// import FileManager from 'laravel-file-manager';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
//Vue.use(VueFroala)
// const store = new Vuex.Store();

// Vue.use(FileManager, {store});

//Vue.use( CKEditor );
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('admin-menu-draggable', require('./components/AdminMenuDraggable.vue').default);
// Vue.component('nested-draggable', require('./components/NestedDraggable.vue').default);
Vue.component('admin-menu-lists-draggable', require('./components/AdminMenuDraggableLists.vue').default);
// Vue.component('sub-draggable', require('./components/SubDrag.vue').default);
Vue.component('admin-page-edit-general', require('./components/AdminPageEditGeneral.vue').default);
Vue.component('admin-menu-nested', require('./components/AdminMenuWithNested.vue').default);
Vue.component('tiny-editor', require('./components/TinyEditor.vue').default);
Vue.component('image-upload', require('./components/ImageUpload.vue').default);
Vue.component('tags-input', require('./components/TagsInput.vue').default);
Vue.component('delete-button', require('./components/DeleteButton.vue').default);
Vue.component('video-druggable', require('./components/VideoDruggable').default);
Vue.component('video-cats-druggable', require('./components/VideoCatsDruggable').default);
Vue.component('experts-druggable', require('./components/ExpertsDruggable').default);
Vue.component('blog-druggable', require('./components/BlogDruggable').default);
Vue.component('dynamic-videos', require('./components/DynamicVideos.vue').default);
Vue.component('tags', require('./components/Tags.vue').default);
Vue.component('dynamic-experts', require('./components/DynamicExperts').default)
Vue.component('blogs', require('./components/Blogs').default)
Vue.component('pagination', require('./components/Pagination').default)
Vue.component('categories', require('./components/Categories').default)
Vue.component('search', require('./components/Search').default)
Vue.component('blogs-search-reset', require('./components/BlogsSearchReset').default)
Vue.component('tags-with-search', require('./components/TagsWithSearch').default)
Vue.component('date-picker-component', require('./components/DatePicker').default)
Vue.component('multiple-cats', require('./components/MultipleParentalCats').default)
/* Vue.component('dynamic-sections-videos', require('./components/DynamicSectionsVideos').default) */
    /**
     * Next, we will create a fresh Vue application instance and attach it to
     * the page. Then, you may begin adding components to this application
     * or customize the JavaScript scaffolding to fit your unique needs.
     **/
import * as axios from 'axios'

import Videos from './modules/Videos'
import BlogsPaginator from './modules/BlogsPaginator'
import Vuex from 'vuex'
import TagsData from './modules/tagsData';
import data from "./modules/vue/data"
import blogsTags from "./modules/vue/blogsTags"
import blogsSearch from "./modules/vue/blogsSearch"
import blogs from "./modules/vue/blogs"
import blogsTagsMeta from "./modules/vue/blogTagsMeta"
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        tagsLock: {},
        locale: 'ru'
    },
    mutations: {
        handleFiltered_blog(state, data) {
            state.blogsSearch.blogs.blogsOveralLength = data.length
            state.blogsSearch.blogs.addBlogs(data)
            state.blogsSearch.blogs = state.blogsSearch.blogs.copy(state.blogsSearch.blogs)
        },
        setCurrentLocale(state, data){
            state.locale = data.locale
        },
        handleFiltered__blog_tag(state, data) {
            if (data.length !== 0) {
                state.blogsTags.addBlogItems(data.blogs)
                state.blogsTags = state.blogsTags.copy(state.blogsTags)
                state.blogsTagsMeta.add = data
            }
        },
        clearSearchVideos(state) {
            state.blogsSearch.blogs.clear()
            state.blogsSearch.blogs = state.blogsSearch.blogs.copy(state.blogsSeacrh.blogs)
        },
        setTagsLock(state, data) {
            state.tagsLock[data.id] = data.state
            state.tagsLock = Object.assign({}, state.tagsLock)
        }
    },
    modules:{
        data,
        blogs,
        blogsSearch,
        blogsTags,
        blogsTagsMeta
    },
    getters: {
        blogs: state => {
            if (!state.blogsSearch.blogs.isEmpty()) {
                return state.blogsSearch.blogs
            } else if (state.blogsTags.blogsTagsMeta.isSomeTagActive) {
                return state.blogsTags.blogs
            } else return state.blogs.blogs
        },
        isResetActive: state => {
            if (state.blogsSearch.blogs.isEmpty()) {
                return false
            } else {
                return true
            }
        },
        isLocked: state => {
            return state.tagsLock
        }
    },
    actions: {
        initBlogs: (context, data) => {
            context.commit('blogs/setBlogs', data.length)
            context.dispatch('blogs/get', data.firstPage);
        },
        // ************************* RESET *****************************
        resetHandler: (context, data) => {
            context.commit('clearSearchVideos');
        },
        // ************************* RESET *****************************
        getFilteredData: (context, data) => {
            if (data.input) {
                axios.get(data.dataId + '/filter', {
                    params: {
                        input: data.input
                    }
                }).then((res) => {
                    const uri_part = data.dataId.replace('/', '_');
                    context.commit('handleFiltered_' + uri_part, res.data)
                }).catch(err => {
                    console.log(err)
                }).finally(res => {
                    context.commit('setTagsLock', { id: Number(data.input.id), state: false })
                })
            }
        },
        tagFilterSet: (context, id) => {
            //context.commit('setTagsLock', { id: Number(id), state: true })
            //if (!context.state.data.data[].videos.empty){
            context.dispatch('data/getByTag', Number(id))
            //}
            context.dispatch('blogsTags/get', Number(id))
        },
        tagFilterUnset: (context, id) => {
            if (!context.state.data.empty) {
                context.commit('data/removeVideosByTag', Number(id))
            }
            if (context.state.blogsTags.blogsTagsMeta.isSomeTagActive) {
                context.commit('blogsTags/removeBlogsByTag', Number(id))
            }
        },
        filterVideosByTag: (context, data) => {
            if (data.section_id)
                context.dispatch('data/loadByTag', data);
            else
                context.dispatch('data/getByTag', data.id);
        },
        getVideos: (context, data) => {
            if (context.state.data.data[data.video_id]){
                if (context.state.data.data[data.video_id].currentlyOnTagVideos === true){
                    if (data.section_id){

                        context.dispatch('data/loadByTag', data);
                    }else{

                        context.dispatch('data/getByTag', context.state.data.data[data.video_id].tagId);
                    }


                }else{
                    context.dispatch('data/get', data)
                }
            }else{
                context.dispatch('data/get', data)
            }
        },
        getSectionVideos: (context, id) => {
            if (context.state.sectionsData.currentlyOnTagVideos === true) {
                context.dispatch('sectionsData/getByTag', Number(id))
            } else {
                context.dispatch('sectionsData/get', Number(id)); 
            }
        },
        setId: (context, id) =>{
            context.state.data.commit('setId', Number(id))
        },
        getBlogs: (context, id) => {
            if (!context.state.blogsTags.blogs.isEmpty()) {
                context.dispatch('blogs/get', id)
            } else context.dispatch('blogs/getDefault', id)
        }
    }
});
const app = new Vue({
    el: '#app',
    store,
    data: {},
    methods: {
        hideFlash: function(e) {
            e.target.parentElement.remove();
        },
        activeFormFilter(e) {
            var date_radio = document.getElementById("date-radio-label");
            var name_radio = document.getElementById("name-radio-label");
            var mail_radio = document.getElementById("mail-radio-label");
            e.target.classList.add("is-active");
            if (e.target.getAttribute('data-attr') === 'date-radio') {
                name_radio.classList.remove("is-active");
                mail_radio.classList.remove("is-active");
            } else if (e.target.getAttribute('data-attr') === 'name-radio') {
                date_radio.classList.remove("is-active");
                mail_radio.classList.remove("is-active");
            } else if (e.target.getAttribute('data-attr') === 'mail-radio') {
                date_radio.classList.remove("is-active");
                name_radio.classList.remove("is-active");
            }
        },
        showFormContent(e) {
            e.target.previousElementSibling.classList.toggle("is-active");
        },
        expandPostCard(e) {
            let show = e.target.closest('.show-options');
            let hide = show.nextElementSibling;
            show.style.display = "none";
            hide.style.display = "flex";
            e.target.closest('.card-header').nextElementSibling.style.display = "flex";
        },
        hidePostCard(e) {
            let hide = e.target.closest('.hide-options');
            let show = hide.previousElementSibling;
            show.style.display = "flex";
            hide.style.display = "none";
            e.target.closest('.card-header').nextElementSibling.style.display = "none";
        },
        activeFilter(e) {
            e.target.parentNode.classList.toggle("is-info");
        },
        activeCatFilter(e) {
            var cats = document.getElementsByClassName('cat-filter-tag');
            Array.from(cats).forEach(function(e) {
                e.classList.remove('is-info');
            });
            e.target.parentNode.classList.toggle("is-info");
            if (e.target.nextElementSibling.checked === true) {
                e.target.nextElementSibling.checked = false;
                e.target.nextElementSibling.disabled = true;
                e.target.parentNode.classList.toggle("is-info");
            }
        },
        activeTab(e) {
            let data = e.target.getAttribute('data-tab');
            let tab1 = document.getElementById('tab1');
            let tab2 = document.getElementById('tab2');
            let tab3 = document.getElementById('tab3');
            let butt1 = document.getElementById('tab_butt_1');
            let butt2 = document.getElementById('tab_butt_2');
            let butt3 = document.getElementById('tab_butt_3');

            if (data === "tab1") {
                tab1.classList.add('is-active');
                tab2.classList.remove('is-active');
                tab3.classList.remove('is-active');
                butt1.classList.add('is-active');
                butt2.classList.remove('is-active');
                butt3.classList.remove('is-active');
            }
            if (data === "tab2") {
                tab2.classList.add('is-active');
                tab1.classList.remove('is-active');
                tab3.classList.remove('is-active');
                butt1.classList.remove('is-active');
                butt3.classList.remove('is-active');
                butt2.classList.add('is-active');
            }
            if (data === "tab3") {
                tab3.classList.add('is-active');
                tab1.classList.remove('is-active');
                tab2.classList.remove('is-active');
                butt1.classList.remove('is-active');
                butt2.classList.remove('is-active');
                butt3.classList.add('is-active');
            }
        },
    }
});