<template>
    <div class="col-12 video-items__bg">
            <div class="row video-items__row load-more-row">
                <div v-for="video in myVideos" class="col-12 col-md-4 video-items__col fade">
                    <div  class="video-items__video">
                        <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + video.youtube_id" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    </div>
                    <span class="title">Default title</span>
                    <span class="desk">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</span>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        props: ['videos'],
        data(){
            return {
                myVideos: this.videos
            }
        }
    }
</script>
