<template>
    <div class="col-12 text-center">
        <ul v-if="pagesLinks.length !== 0 && pagesLinks.length > 1" class="pagination">
            <li :class="{'prev': true, 'disabled': currentPage === 1}" @click="handleClick" :id="currentPage - 1">{{i}}</li>
            <li v-for="i in pagesLinks" :key="i" :class="{'active': i === currentPage}">
                <a v-if="i != 'hideLink'" href="#" :id="i" @click="handleClick">{{i}}</a>
                <a v-else>...</a>
            </li>
            <li :class="{'next': true, 'disabled': currentPage === pagesQuantity}" @click="handleClick" :id="currentPage + 1"></li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'pagination',
        props: {},
        methods:{
            handleClick(e) {
                e.preventDefault()
                const id = Number(e.currentTarget.id)
                if (id >= 1 && id <= this.pagesQuantity){ 
                    if (id != this.currentPage){
                        this.$store.dispatch('getBlogs', id)
                    }
                }
            }
        },
        computed:{
            pagesQuantity(){
                return this.$store.getters.blogs.pagesQuantity
            },
            pagesLinks(e){
                return this.$store.getters.blogs.pagesLinks
            },
            currentPage(){
                return this.$store.getters.blogs.currentPageNumber
            },
            paginatorLinksQuantity(){
                return this.$store.getters.blogs._paginatorLinksQuantity
            }
        }
    }
</script>
<style scoped>

</style>
