<template>
    <div class='row blogs-custom'>
        <div v-for="(blog, i) in blogsData" :key="i" :class="{'col-12': true, 'col-md-8':  blog.is_promo ? true : false, 'col-md-4': blog.is_promo ? false : true, 'blog-list__col': true}">
            <div class="blog-list__item">
                <a :href="blog.path" class="link-wrapper">
                <span class="date">{{blog.created_at.split('T')[0]}}</span>
                <!--366x210-->
                    <img :src="blog.is_promo ?  blog.promo_preview : blog.preview" alt="">              
                
                    <span class="title">{{blog.content.title}}</span>
                    <span class="desk" v-html="blog.content.content"></span>
                    <a class="read-more">{{ locale === 'ru' ? 'Читать полностью' : 'Читати повністю'}}</a>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'blogs',
        props: {           
            currentpage: Number,           
            overalLength: Number,
            locale: String
        },
        created() {
            this.$store.commit('setCurrentLocale', {locale: this.locale})
            this.$store.dispatch('initBlogs', {length: this.overalLength, firstPage: 1})
        },
        data() {
            return {
              pagesQuantity: this.$store.getters.blogs.pagesQuantity
            }
        },
        computed:{
            blogsData(){
                return this.$store.getters.blogs.currentPage
            }
        }
    }
</script>
<style scoped>
.blog-list__col{
    opacity: 1;
    transition: .5s;
    -webkit-transition: .5s;
}
.date{
    opacity: 1;
}
@media screen and (max-width: 767px) {
    .blogs-custom{
        margin-top: 40px;
    }
}
</style>
