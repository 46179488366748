import BlogsPaginator from "../BlogsPaginator";
import TagsData from "../tagsData";

export default{
  namespaced: true,
    state: () => ({ 
        blogs: new BlogsPaginator(10,1,4),
        blogsTagsMeta: new TagsData()
     }),
     
     mutations: {
      removeBlogsByTag(state, id) {
        let listToDelete = state.blogsTagsMeta.remove(id)
        listToDelete.forEach(i => state.blogs.remove(i))
        state.blogs = state.blogs.copy(state.blogs)
    }, 
      getPage(state, id) {
          state.blogs.pageId(Number(id))
          state.blogs = state.blogs.copy(state.blogs)
      },
      setBlogs(state, overalLength) {
          state.blogs.blogsOveralLength = overalLength
          state.blogs = state.blogs.copy(state.blogs)
      },
      add(state, data) {
        state.blogs.addBlogItems(data.blogs)
        state.blogsTagsMeta.add = data
        state.blogs = state.blogs.copy(state.blogs)
      }
     },
    actions: { 
      get: (context, id) =>{
          const offset = context.state.blogs.perPage * (context.state.blogs.currentPageNumber - 1)
          if (!context.state.blogs.hasBlogsChunk(id - 1)) {
              axios.get('blog/tag/filter', {
                  params: {
                    input:{
                      id,
                      offset,
                      quantity: context.state.blogs.perPage
                    }
                      
                  }
              }).then(res => {
                  context.commit('add', res.data);
                  context.commit('setTagsLock', { id: Number(id), state: false }, {root: true})
              }).catch(err => console.log(err))
            } else {
              context.commit('getPage', id)
              context.commit('setTagsLock', { id: Number(id), state: false }, {root: true})
            }
      }
     },
  getters: {  }
}