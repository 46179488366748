import CopyInterface from './CopyInterface'
class Videos {
    constructor(quantity){
        this._activeTags = []
        this._videos = []
        this._tag_id = null
        this._id = null
        this._tagsData = {}
        this._quantity = quantity
        this._allVideosSent = false
        this._initialVideosIds = []
        this._section_id = null
    }
    // ************************* state.data ****************************
    get empty(){
        if (this._videos.length === 0){
            return true
        }else return false
    }
    get allVideos() {
        return this._videos
    }
    get tagsData() {
        return this._tagsData
    }
    get isAllTagsVideosSent() {
        for (let key in this._tagsData) {
            if (this._tagsData[key].allVideosSent !== true) {
                return key
            }
        }
          return true
    }
    get videos() {
        let result = []
        const activeTags = this._activeTags
        const tagsData = this._tagsData
        if (activeTags.length !== 0){
            result = this._videos.filter(video => {
                return activeTags.some(tag => tagsData[tag] ? tagsData[tag].videos.includes(video.id): false)
            })

        }else{
            result = this._videos.filter(video =>
            this._initialVideosIds.includes(video.id)
            )
        }
        return result
    }
    get  tagOffset() {
    if (!this._tagsData[this._tag_id]) return 0
        else return this._tagsData[this._tag_id].videos.length
    }
    get  offset() {
        if (this._activeTags.length === 0){
            return this._initialVideosIds.length
        }else {
            if (!this._tagsData[this._tag_id]) return 0;
                return this._tagsData[this._tag_id].videos.length
            }
        }
    get  allVideosSent() {
        return this._allVideosSent
    }
    get  id() {
        return this._id
    }
    get  quantity () {
        return this._quantity
    }
    get section_id (){
        return this._section_id
    }
    get  currentlyOnTagVideos() {
        if (this._activeTags.length !== 0) return true
        else return false
    }
    get  getAvaibleTagId() {
        if (!this._tagsData.hasOwnProperty(this._tag_id)) return null
        else{
            for (let i = 0; i < this._activeTags.length; i++) {
                if (this._tagsData[this._activeTags[i]]){
                    if (this._tagsData[this._activeTags[i]].allVideosSent !== true){
                        this._tag_id = this._activeTags[i]
                        return this._activeTags[i]
                    }

                }
            }
            return null
        }
    }
    get   tagId()  {
        return this._tag_id
    }
    get  videosOffset() {
        return this._offset
    }
    get  initialVideosIds() {
    return this._initialVideosIds
    }
    set  setId(id){
    this.id = Number(id)
    }
    // ************************* SETTERS ****************************
    set section_id (id){
        this._section_id = id
    }
    set id(id){
        this._id = Number(id)
    }
    set videos(data){
        this._videos = [...this._videos,...data.videos.filter(video => {
          if (this._videos.every(local_video =>  local_video.id !== video.id)){
            return true
          }else {
            return false
          }
        })]
        data.videos.forEach(video => {
          this._initialVideosIds.push(video.id)
        })
        this._initialVideosIds = Array.from(new Set(this._initialVideosIds))
        this._allVideosSent = data.allVideosSent
        
     }
    set tagVideos(data){
        const id = Number(data.tagInfo.id)
          if (this._tagsData.hasOwnProperty(id)){
              this._tagsData[id].allVideosSent = data.tagInfo.allVideosSent
              this._tagsData[id].videos  = this._tagsData[id].videos.concat(data.tagInfo.videos.map(video => video.id))
          }else {
              this._tagsData[id] = {}
              this._tagsData[id].allVideosSent = data.tagInfo.allVideosSent
              this._tagsData[id].videos = data.tagInfo.videos.map(video => video.id)
          }
          this._videos = [...this._videos,...data.tagInfo.videos.filter(video => this._videos.every(local_video => local_video.youtube_id !== video.youtube_id))]
      }
      // ************************* METHODS ****************************
    addActiveTag(id){
        this._tag_id = Number(id)
        if (!this._activeTags.includes(Number(id))){
            this._activeTags = [...this._activeTags, Number(id)]          
        }
    }
    removeVideosByTag(id){
        this._activeTags = this._activeTags.filter(tag => tag !== Number(id))
    }
    copy(obj){
        return Object.assign(Object.create(this), obj)
    }
};
export default Videos