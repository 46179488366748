<template>
    <div>
        <vue-tags-input
            v-model="tag"
            :tags="tags"
            :autocomplete-items="autocompleteItems"
            @tags-changed="newTags => tags = newTags"
            @before-adding-tag="saveTags"
            @before-deleting-tag="killTag"
        />
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import * as axios from 'axios'
    export default {
        props: ['tagsource', 'alltags', 'type', 'target'],
        components: {
            VueTagsInput,
        },
        data() {
            return {
                tag: '',
                tags: this.tagsource,
                autocompleteItems: this.alltags,
            };
        },
        methods:{
            saveTags(obj){
                axios.post('/admin/tag/create', {
                    'text': obj.tag.text,
                    'type': this.type,
                    'target': this.target,
                })
                    .then((response) => {
                        if(response) {
                            console.log(response);
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.message = 'Что-то пошло не так...';
                    });
                obj.addTag();
            },
            killTag(obj){
                console.log(obj.tag.id);
                if(obj.tag.id) {
                    axios.patch('/admin/tag/delete', {
                        'id': obj.tag.id,
                        'target': this.target,
                    })
                        .then((response) => {

                        })
                        .catch((error) => {
                            this.loading = false;
                            this.message = 'Что-то пошло не так...';
                        });
                }
                obj.deleteTag();
            }
        }
    };
</script>
