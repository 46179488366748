<template>
    <div class="menu-container">
        <draggable v-model="pages" class="container drug-dealer" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="rebuildOrder">
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <div v-for="element in pages" :key="element.id" class="card">
                        <header class="card-header">
                            <p class="card-header-title"><a href="#">{{element.title}}</a></p>
                            <a href="#" class="card-header-icon show-options" aria-label="more options" v-on:click="showOptions">
                                  <span class="icon">
                                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                                  </span>
                            </a>
                            <a href="#" class="card-header-icon hide-options" aria-label="more options" v-on:click="hideOptions">
                                  <span class="icon">
                                    <i class="fas fa-angle-up" aria-hidden="true"></i>
                                  </span>
                            </a>
                        </header>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item">Edit</a>
                            <a href="#" class="card-footer-item red">Delete</a>
                        </footer>
                    </div>
                </transition-group>
        </draggable>
        <button v-on:click.prevent="sendForm" :disabled="loading" class="button is-primary">Update <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

  export default {
    props: ['list'],
    data() {
      return {
        pages: this.list,
        drag: false,
        loading: false,
        message: '',
      }
    },
    components: {
      draggable
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "pages",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    methods: {
      sort() {
        this.pages = this.pages.sort((a, b) => a.order - b.order);
      },
      rebuildOrder(e){
        console.log(this.pages);
        console.log(e);
        this.pages.forEach(el => el.order = (this.pages.indexOf(el)));
      },

      sendForm(){
        this.loading = true;
        axios.patch('/admin/menu/order', {
          'pages': this.pages,
        })
          .then((response) => {
            if(response) {
              this.loading = false;
              this.message = 'Updated successfully';
            }
          })
          .catch((error) => {
            this.loading = false;
            this.message = 'Something went wrong!';
            });
          },
      showOptions(e){
        let show = e.target.closest('.show-options');
        let hide = show.nextElementSibling;
        show.style.display = "none";
        hide.style.display = "flex";
        e.target.closest('.card-header').nextElementSibling.style.display = "flex";
      },
      hideOptions(e){
        let hide= e.target.closest('.hide-options');
        let show = hide.previousElementSibling;
        show.style.display = "flex";
        hide.style.display = "none";
        e.target.closest('.card-header').nextElementSibling.style.display = "none";
      }
    },
  }
</script>
<style>
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
</style>
