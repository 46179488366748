import BlogsPaginator from "../BlogsPaginator";

export default{
    namespaced: true,
    state: () => ({  
        blogs: new BlogsPaginator(10,1,4)
    }),
  mutations: { 
    getPage(state, id) {
        state.blogs.pageId(Number(id))
        state.blogs = state.blogs.copy(state.blogs)
    },
    setBlogs(state, overalLength) {
        state.blogs.blogsOveralLength = overalLength
        state.blogs = state.blogs.copy(state.blogs)
    },
    add(state, data) {
        state.blogs.blogsOveralLength = data.length
            state.blogs.addBlogs(data)
            state.blogs = state.blogs.copy(state.blogs)
    }
   },
  actions: { 
    get: (context, id) =>{
        const offset = context.state.blogs.perPage * (id - 1)
        if (!context.state.blogs.hasBlogsChunk(id - 1)) {
            axios.get('/blog/retrieve/blogpost', {
                params: {
                    offset,
                    quantity: context.state.blogs.perPage
                }
            }).then(res => {
                context.commit('add', { blogs: res.data, id: id - 1 });
            }).catch(err => console.log(err))
        } else {
            context.commit('getPage', id)
        }
      },
   },
  getters: {  }
}