
document.addEventListener('DOMContentLoaded', () => {
    function footerBottom() {
        var heightHeader = document.getElementById('header_').offsetHeight
        var marginFooter = window.getComputedStyle(document.getElementById('footer_'))
        marginFooter = +(marginFooter.getPropertyValue('margin-top').replace('px', ''))
        var heightFooter = document.getElementById('footer_').offsetHeight + marginFooter
        var windowHeight = window.innerHeight
        document.getElementById('content_').style.minHeight = windowHeight - heightHeader - heightFooter + 'px';
    }
    $(document).ready(function() {
        footerBottom();
        inputLabels();
        seoText();
        tagsMob();
        blogView();
        animations()
        addHoverStyles();
        function addHoverStyles(){
            /* $(document).addEventListener('mouseout', (e) => {
                if ($(e.target).hasClass('scroll-arrow')){
                    arrow.classList.remove('green-button')
                    $('.scroll-arrow .text-custom').remove()
                    $('.scroll-arrow').append('<div class="text">Далее</div>')
                }
            })
            $(document).addEventListener('mouseover', (e) => {
                $('.scroll-arrow .text').remove()
                        $('.scroll-arrow').append('<div class="text-custom">Далее</div>')
                        arrow.classList.add('green-button')
            }) */
            $('.scroll-arrow').each(function ()
            {
                let arrow = this;

                this.addEventListener('mouseout', function (e){
                   
                })

                this.addEventListener('mouseover', function(e){
                    /* if ($(e.target).hasClass('scroll-arrow')){ */
                        
                    /* } */
                })
            });

        }
        function animations() {
            var section2Timeout = 650;
            var section3Timeout = 300;
            var section4Timeout = 650;
            var sectionContacts = 650;
            var feedbackTimeout = 400;
            var blogTimeout = 400;
            var expertsTimer = 400;
            var status3 = true;
            var status4 = true;
            var status5 = true;
            if ($('.section-2').length) {
                $('.section-2-animation-1').addClass('fade');
                setTimeout(function() {
                    $('.section-2-animation-2').addClass('fade')
                }, section2Timeout)
                setTimeout(function() {
                    $('.section-2-animation-3').addClass('fade')
                }, section2Timeout * 2)
                setTimeout(function() {
                    $('.section-2-animation-4-1').addClass('fade')
                }, section2Timeout * 3)
                setTimeout(function() {
                    $('.section-2-animation-4-2').addClass('fade')
                }, section2Timeout * 3.35)
                setTimeout(function() {
                    $('.section-2-animation-5').addClass('fade')
                }, section2Timeout * 4.7)
                setTimeout(function() {
                    $('.section-2-animation-6').addClass('fade')
                }, section2Timeout * 6)
                setTimeout(function() {
                    $('.section-2-animation-7').addClass('fade')
                }, section2Timeout * 7)
            }
            $(window).scroll(function() {
                var windowHeight = $(window).height();
                var scrollTop = $(window).scrollTop();
                if ($('.section-3').length) {
                    var section3Pos = $('.section-3').offset().top;
                    if (scrollTop >= section3Pos - windowHeight / 2) {
                        if (status3) {
                            status3 = false
                            var sect3Block = $('.section-3-col');
                            $('.section-3__text-black').addClass('fade');
                            setTimeout(function() {
                                $('.section-3__text-green').addClass('fade')
                            }, section3Timeout, function() {

                            })
                            setTimeout(function() {
                                for (var i = 1; i < sect3Block.length; i++) {
                                    (function(i) {
                                        setTimeout(function() {
                                            $(sect3Block[i]).addClass('fade');
                                        }, section3Timeout * i);

                                    })(i);
                                }
                            }, 400)
                        }
                    }
                }
                if ($('.section-4').length) {
                    var section4Pos = $('.section-4').offset().top;
                    if (scrollTop >= section4Pos - windowHeight / 2 + 250) {
                        if (status4) {
                            status4 = false;
                            for (var i = 1; i < 7; i++) {
                                (function(i) {
                                    setTimeout(function() {
                                        $('.form-anim-' + i).addClass('fade');
                                    }, section4Timeout * (i));

                                })(i);
                            }
                        }
                    }
                }
                if ($('.contacts-form').length) {
                    var sectionContactForm = $('.contacts-form').offset().top;
                    if (scrollTop >= sectionContactForm - windowHeight / 2) {
                        if (status5) {
                            status5 = false;
                            for (var i = 1; i < 7; i++) {
                                (function(i) {
                                    setTimeout(function() {
                                        $('.form-anim-' + i).addClass('fade');
                                    }, section4Timeout * (i));

                                })(i);
                            }
                        }
                    }
                }
                if ($('.video-items').length) {

                    $('.video-items').each(function() {
                        if (!($(this).hasClass('end-fade'))) {
                            var sectionFeedback = $(this).offset().top;
                            var feedbackItems = $(this).find('.video-items__col');
                            if (scrollTop >= sectionFeedback - windowHeight / 2) {
                                for (var i = 0; i < feedbackItems.length; i++) {
                                    $(this).addClass('end-fade');
                                    (function(i) {
                                        setTimeout(function() {
                                            $(feedbackItems[i]).addClass('fade');
                                        }, feedbackTimeout * (i));

                                    })(i);
                                }
                            }
                        }
                    })




                }

            })

            if ($('.blog-list .container .row').children().length) {
                var blogBlock = $('.blog-list').offset().top;
                var blogItems = $('.blog-list__col');
                // if(scrollTop>=blogBlock - windowHeight / 2 ) {
                //
                // }
                for (var i = 0; i < blogItems.length; i++) {
                    (function(i) {
                        setTimeout(function() {
                            $(blogItems[i]).addClass('fade');
                        }, blogTimeout * (i));
                    })(i);
                }
            }

            var contactsSocial = $('.contacts-page__links a');
            if (contactsSocial.length) {
                for (var i = 0; i < contactsSocial.length; i++) {
                    (function(i) {
                        setTimeout(function() {
                            $(contactsSocial[i]).addClass('fade');
                        }, sectionContacts * (i));

                    })(i);
                }
            }

            var experts = $('.experts__col');
            if (experts.length) {
                for (var i = 0; i < experts.length; i++) {
                    (function(i) {
                        setTimeout(function() {
                            $(experts[i]).addClass('fade');
                        }, expertsTimer * (i));

                    })(i);
                }
            }

            var pageTitles = $('.page-title__title');
            if (pageTitles.length) {
                pageTitles.addClass('fade');
            }

            $('.pages-anim-1').addClass('fade');
            setTimeout(function() {
                $('.pages-anim-2').addClass('fade')
            }, section2Timeout)
            setTimeout(function() {
                $('.pages-anim-3').addClass('fade')
            }, section2Timeout * 2)
        }

        function blogView() {
            if ($('.blog-view-list').length) {
                var startScrollPosition = $(window).scrollTop();
                $(window).scroll();
                if ($(window).width() <= 767) {
                    $('.blog-list__col:last-child').removeClass('fixed');
                    $('.blog-list__col:last-child').removeClass('absolute');
                    $('.blog-list__col:last-child').attr('style', '');
                }
                $(window).scroll(function() {
                    if ($(window).width() >= 768) {
                        var windowHeight = $(window).height();
                        var scrollPosition = $(window).scrollTop();
                        var sideBarPosition = $('.blog-view-list').offset().top;
                        var sideBarHeight = $('.blog-view-list').height();
                        var firstBlock = $('.blog-list__col');
                        var firstBlockWidth = $(firstBlock[0]).innerWidth();
                        var lastBlock = $('.blog-list__col:last-child');
                        var lastblockHeight = lastBlock.height()
                        var lastBlockPosition = lastBlock.offset().top;
                        var lastblockBottmMargin = windowHeight - lastblockHeight
                        var blog = $('.blog-view__row')
                        var blogHeight = blog.height()
                        var blogPosition = blog.offset().top;


                        if (startScrollPosition > scrollPosition) {
                            // up
                            if (scrollPosition < ((blogHeight + blogPosition) - windowHeight) + lastblockBottmMargin && scrollPosition > (sideBarPosition + sideBarHeight)) {
                                lastBlock.removeClass('absolute');
                            }
                            if (scrollPosition <= (sideBarPosition + sideBarHeight)) {
                                removeFixed()
                            }

                        } else {
                            //down
                            if (scrollPosition >= (sideBarPosition + sideBarHeight) - lastblockHeight) {
                                lastBlock.addClass('fixed');
                                lastBlock.css({ width: firstBlockWidth });

                            }
                            if (scrollPosition >= ((blogHeight + blogPosition) - windowHeight) + lastblockBottmMargin) {
                                lastBlock.addClass('absolute');

                            }

                        }


                        function removeFixed() {
                            lastBlock.removeClass('fixed');
                            lastBlock.removeClass('absolute');
                            lastBlock.attr('style', '');
                        }


                        startScrollPosition = $(window).scrollTop();
                    }

                })
            }
        }

        /*$(document).on('click', '.load-more', function () {
            var this_ = $(this);
            load(this_)
        });*/

        function load(this_) {
            var feedbackTimeout = 400;
            this_.addClass('load');
            this_.parents('.load-more-row').addClass('load-row');

            var posCatalog = this_.parents('.load-more-row').offset().top;
            var heightCatalog = (this_.parents('.load-more-row').height()) - ((this_.height()));
            var marginItems = 20;
            var newPosScroll = heightCatalog + posCatalog - marginItems;
            $.ajax({
                method: "GET",
                success: function() {

                },
                error: function() {

                },
                complete: function() {
                    //после натяжки удалить первый setTimeout и запустить функцию completeLoad() и fadeInBlocks() (поставил таймаут и три блока для примера чтоб можно было в верстке посмотреть как будет выглядеть)
                    setTimeout(function() {
                        var addItems = '<div class="col-12 col-md-4 video-items__col">' +
                            '<div class="video-items__item">' +
                            '<div class="video-items__video">' +
                            '<iframe width="560" height="315" src="https://www.youtube.com/embed/mqwnXs2oxOc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>' +
                            '</div>' +
                            '<span class="title">Aenean euismod bibendum laoreet</span>' +
                            '<span class="desk">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</span>' +
                            '</div>' +
                            '</div>'
                        this_.parents('.video-items__bg').find('.load-more-row').find('.load-more').parent().prev().after(addItems)
                        this_.parents('.video-items__bg').find('.load-more-row').find('.load-more').parent().prev().after(addItems)
                        this_.parents('.video-items__bg').find('.load-more-row').find('.load-more').parent().prev().after(addItems)

                        completeLoad();
                        fadeInBlocks();
                    }, 1500)

                    function fadeInBlocks() {
                        setTimeout(function() {
                            var oldBlocksLength = this_.parents('.load-more-row').find('.video-items__col.fade').length;
                            var blocks = this_.parents('.load-more-row').find('.video-items__col');
                            for (var i = oldBlocksLength; i < blocks.length; i++) {
                                (function(i) {
                                    setTimeout(function() {
                                        $(blocks[i]).addClass('fade')
                                    }, feedbackTimeout * (i - oldBlocksLength));

                                })(i);
                            }
                        }, 350 + 500 + 200)
                    }

                    // completeLoad();
                    function completeLoad() {
                        $('.load-more').removeClass('load');
                        $('.load-more-row').removeClass('load-row');
                        setTimeout(function() {
                            $('body, html').animate({ scrollTop: newPosScroll }, 400);
                        }, 350);
                    }
                }
            });
        }

        function tagsMob() {
            $(document).on('click', '.section-tags__title', function() {
                if ($(window).width() <= 767) {
                    $('.section-tags__wrapp').addClass('show')

                }
            })
            $(document).on('click', '.section-tags__hide span', function() {
                $('.section-tags__wrapp').removeClass('show')
            })

        }


        function seoText() {
            $(document).on('click', '.read-more span', function() {
                var textHide = $(this).data('hide');
                var textRead = $(this).data('read');
                if ($('.section-seo .text').hasClass('show')) {
                    $('.section-seo .text').removeClass('show')
                    $(this).html(textRead)
                } else {
                    $('.section-seo .text').addClass('show')
                    $(this).html(textHide)
                }
            })
        }

        function inputLabels() {
            $('input, textarea').focus(function() {
                $(this).parents('.input-wr').addClass('hide-label')
            })
            $('input, textarea').focusout(function() {
                var val = $(this).val();
                if (val < 1) {
                    $(this).parents('.input-wr').removeClass('hide-label')
                }
            })
        }

        tabletMenu();

        function tabletMenu() {
            $(document).on('click', '.last-menu-buttons', function() {

                if ($('.last-menu').hasClass('active')) {
                    $('.last-menu').removeClass('active');
                } else {
                    $('.last-menu').addClass('active');
                }

            })
        }
        $(document).on('click', function(e) {
                var div = $(".last-menu");
                var div2 = $(".last-menu-buttons");
                if (!div.hasClass('active')) {

                }

                if ((!div.is(e.target) && div.has(e.target).length === 0) && (!div2.is(e.target) && div2.has(e.target).length === 0)) {
                    div.removeClass('active')

                }

            })
            // menu mobile
        function menuMobAdd() {
            // $('.main-menu').addClass('visible');
            if (!($('body').find('.mob-overlay-new').length)) {
                $('body').append('<div class="mob-overlay-new"></div>')
            }
            $('.mob-overlay-new').fadeIn(300);
            $('body').addClass('off-scroll');
            setTimeout(function() {
                $('.main-menu').addClass('open');
            }, 10)
        }
        $(document).on('click', '.menu_mob_new', function() {
            menuMobAdd();
        })

        function closeMenuNew() {
            $('.main-menu').removeClass('open');


            $('.mob-overlay-new').fadeOut(300);
            $('body').removeClass('off-scroll');
        }
        $(document).on('click', '.close-menu-new', function() {
            closeMenuNew();
        })
        $(document).on('click', '.mob-overlay-new', function() {
            closeMenuNew();
        })
        mobHiddenMenu();

        function mobHiddenMenu() {
            $('.main-menu .link').click(function() {
                $(this).next().toggleClass('visible')
            })
        }
        // end menu mobile

        buttonsScroll();

        function buttonsScroll() {
            $('.arrows-wrapp').click(function() {
                var scrollPosition;
                if ($(this).hasClass('next')) {
                    scrollPosition = $('.section-2').offset().top;
                } else if ($(this).hasClass('up')) {
                    scrollPosition = 0;
                }
                $('body, html').animate({ scrollTop: scrollPosition }, 500)
            })
        }





        function overlayModalAdd() {
            if ($('body').find('.overlay-modal').length > 0) {} else {
                $('body').append('<div class="overlay-modal" style="display: none"></div>')
            }
            $('.overlay-modal').fadeIn(300);
            $('body').addClass('body-modal-active');
        }


        modalForms();

        function modalForms() {
            $('body').on('click', '.modal-link', function(e) {
                e.preventDefault();
                e.stopPropagation();
                overlayModalAdd()
                console.log('Hello')
                var idForm = $(this).data('form');

                var pos = ($(window).scrollTop()) + 40;

                $('.overlay-modal').fadeIn(400,
                    function() {
                        $('#' + idForm)
                            .css('display', 'block')
                            .animate({ opacity: 1, top: pos }, 200);
                    });
            })
        }
        closeForms();

        function closeForms() {
            $(document).on('click', '#modal_close, .overlay-modal', function() {
                $('.forms_').animate({ opacity: 0, top: '0' }, 200, function() {
                    $(this).css('display', 'none');
                    $(this).prev(".overlay-modal").css('display', 'none');
                    $('.overlay-modal').fadeOut(400);
                    $('body').removeClass('body-modal-active');
                });
            })
            $('#modal_close, .overlay-modal').click(function() {


            });
        }
        arrowScroll();

        function arrowScroll() {
            $(document).on('click', '.scroll-arrow', function() {
                if ($(this).hasClass('arrows-top')) {
                    $('body,html').animate({ scrollTop: $('.section-2').offset().top }, 500)
                } else {
                    $('body,html').animate({ scrollTop: 0 }, 500)
                }
            })
        }






        $(window).resize(function() {
            footerBottom();
            blogView();
            if ($(window).width() >= 768) {
                closeMenuNew();
            }
        })
    })
});
