<template>
    <div class="m-image_upload">
        <label v-bind:for="id" class="button is-info custom_upload"><span><i class="fas fa-upload"></i></span>выбрать файл</label>
        <input type="file" v-bind:id="id" v-bind:name="name"  class="inputfile" @change="previewFiles" accept="image/*">
        <div class="image_name">
            {{ val }}
        </div>
        <div class="image_preview" :class="{ 'd-none': disabled }">
            <img v-if="path" v-bind:src="path">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            path:[String, null],
            name:[String],
            id:[String],
        },
        methods: {
            previewFiles(e){
                this.val = e.target.files[0].name;
                this.disabled = true;
            }
        },
        mounted(){
         console.log(this.path);
        },
        data() {
            return {
               val: '',
                disabled: false,
            };
        },
    }
</script>
