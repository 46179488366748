<template>
<div class="field m-parent-page">
    <label>Родительские страницы / категории</label>
    <div class="col-2">
        <div class="row">
            <div class="col-12"><button type="button" v-if="isSomeCatsAvailableLeft" class="button is-success btn-modifier" @click="addCat">Добавить</button></div>
            <div class="col-12 save-btn-wrapper"><button v-if="hasDataToSave" type="button" class="button is-success btn-modifier btn-save" @click="updateCats">Сохранить</button></div>            
        </div>
    </div>    
        
    <div class="select is-info">
        <div class="row">
                <div :class="{'col-3': true,  'cat-item': true, 'last-cat-item': !isNotLast}" v-for="(category, index) in currentCats" :key="index">
                    <button v-if="isNotLast" type="button" class="trash-button" @click="removeCat(index)"></button>
                    <select name="parent_id" v-model="category.id" @change="handleChange">          
                        <option v-for="(catAvailable, index) in  catsAvailable(category.id)" :key="index" :value="catAvailable.id">{{catAvailable.title}}</option>                                                                      
                    </select>
                    
                </div>
        </div>
        <div v-if="errMsg">{{errMsg}}</div>
    </div>
</div>
</template>

<script>
import * as axios from 'axios'
export default {
    props: {
        'cats': {
            type: Array,
            required: true
        },
        'currentCategories': {
            type: Array
        },
        'query' :{
            type: String,
            required: true
        },
        'video_id' : {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            'currentCats': this.currentCategories ?? [],
            'errMsg': '',
            'hasDataToSave': false
        }
    },
    methods: {
        addCat() {
            const firstAvailableCat = this.catsAvailable()[0]
            this.hasDataToSave = true
            
            if (firstAvailableCat){

                this.currentCategories.push({id :firstAvailableCat.id, title: firstAvailableCat.title})
            }else{
                this.errMsg = 'Больше нет свободных категорий!'
                setTimeout(()=> this.errMsg = '', 2000)
            }
        },
        removeCat(index){
            this.currentCats.splice(index, 1);
            this.hasDataToSave = true
        },
        updateCats(){
            axios.patch(this.query, {
                categories: this.currentCats,
                id: this.video_id
            })
            .then(res => {
                this.currentCats = res.data
                this.hasDataToSave = false
            }
            )
            .catch(res => {                
            }
            )
        },
        handleChange(){
            this.hasDataToSave = true
        },
        catsAvailable(index){
            return this.cats.filter((commonCat, i) => index === commonCat.id ? true: this.currentCats.every(currentCat => currentCat.id !== commonCat.id))
        }
    },
    computed:{
        isNotLast(){
            return this.currentCats.length > 1
        },
        isSomeCatsAvailableLeft(){
            return this.cats.filter((commonCat, i) =>  this.currentCats.every(currentCat => currentCat.id !== commonCat.id)).length > 1
        }
    }
};
</script>

<style scoped>
select{
    width: 100%;
    text-overflow: ellipsis;
}
.select select:not([multiple]) {
    padding-right: 1.5em;
}
.is-info{
    width: 100%;
}
.btn-modifier{
    margin-top: 0px;
}
.cat-item{
    margin-bottom: 20px;
}
.cat-item:hover>.trash-button{
    display: block;
}
.trash-button{
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    border: none;
    background: url(/images/admin/multiple-parents/trash.png) 0 0 no-repeat;
    background-size: contain;
    right: 7%;
    top: 10%;
    z-index: 1;
}
.save-btn-wrapper{
    margin-top: 10px;
}
.btn-save{
    background-color: #2c63ff;
}
.cat-item:after{
    border: 3px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(
-45deg
);
    right: 8%;
    transform-origin: center;
    width: 0.625em;
    border-color: #3298dc;
}
.select:not(.is-multiple):not(.is-loading)::after{
    display: none !important;
}
.cat-item:hover:not(.last-cat-item).cat-item:after{
    display: none;
}
</style>
