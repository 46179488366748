<template>
    <div class="m-blog-druggable">
        <draggable v-model="posts" class="container" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="rebuildOrder">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="blog_wrapper">
                <div v-for="element in posts" :key="element.id" class="video_block">
                    <div v-if="element.is_promo == 0" class="blogpost">
                        <div v-text="element.order"></div>
                        <div class="blogpost_img">
                            <img :src="element.preview">
                        </div>
                    </div>
                    <div v-if="element.is_promo == 1" class="blogpost_promo">
                        <div v-text="element.order"></div>
                        <div class="blogpost_img">
                            <img :src="element.preview">
                        </div>
                    </div>
                </div>
            </transition-group>
        </draggable>
        <button v-on:click.prevent="sendForm" :disabled="loading" class="button is-primary">Update <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        props: {
            list: [Array,null]
        },
        data() {
            return {
                posts: this.list,
                drag: false,
                loading: false,
                message: '',
            }
        },
        components: {
            draggable
        },
        mounted() {
            console.log(this.posts);
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "videos",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            sort() {
                this.videos = this.videos.sort((a, b) => a.order - b.order);
            },
            rebuildOrder(e){
                console.log(this.videos);
                console.log(e);
                this.videos.forEach(el => el.order = (this.videos.indexOf(el)));
            },

            sendForm(){
                this.loading = true;
                axios.patch('/admin/video/order', {
                    'videos': this.videos,
                })
                    .then((response) => {
                        if(response) {
                            this.loading = false;
                            this.message = 'Updated successfully';
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.message = 'Something went wrong!';
                    });
            },
            showOptions(e){
                let show = e.target.closest('.show-options');
                let hide = show.nextElementSibling;
                show.style.display = "none";
                hide.style.display = "flex";
                e.target.closest('.card-header').nextElementSibling.style.display = "flex";
            },
            hideOptions(e){
                let hide= e.target.closest('.hide-options');
                let show = hide.previousElementSibling;
                show.style.display = "flex";
                hide.style.display = "none";
                e.target.closest('.card-header').nextElementSibling.style.display = "none";
            }
        },
    }
</script>
