<template>
    <div class="container">
        <form method="patch" :action="this.action">
            @csrf
            <div class="field">
                <input type="text" name="title">
            </div>

            <input type="submit" value="create" class="button is-primary">
        </form>
    </div>
</template>

<script>
  export default {
    props: ['page'],
    data() {
      return {
        page: this.page,
        drag: false,
        loading: false,
        message: '',
        action: 'admin/page/' + page.id,
      }
    },
    components: {

    },
    computed: {

    },
    methods: {

    },
  }
</script>
<style>

</style>
