<template>
    <div class="m-video-druggable">
        <draggable v-model="videos" class="container" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="rebuildOrder">
           <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="video_wrapper">
                <div v-for="element in videos" :key="element.id" class="video_block">
                   <div v-if="element.is_promo == 0" class="video">
                       <div v-text="element.order_common"></div>
                        <iframe width="300" height="150"  :src='"https://www.youtube.com/embed/"+ element.youtube_id' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                   </div>
                   <div v-if="element.is_promo == 1" class="video_promo">
                       <div v-text="element.order_common"></div>
                       <iframe width="300" height="150"  :src='"https://www.youtube.com/embed/"+ element.youtube_id' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                   </div>
                </div>
           </transition-group>
        </draggable>
        <button v-on:click.prevent="sendForm" :disabled="loading" class="button is-primary">Сохранить <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        props: {
            list: [Array,null]
        },
        data() {
            return {
                videos: this.list,
                drag: false,
                loading: false,
                message: '',
            }
        },
        components: {
            draggable
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "videos",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            // sort() {
            //     this.videos = this.videos.sort((a, b) => a.order - b.order);
            // },
            rebuildOrder(e){
                console.log(this.videos);
                console.log(e);
                this.videos.forEach(el => el.order_common = (this.videos.indexOf(el)));
            },

            sendForm(){
                this.loading = true;
                axios.post('/admin/video/comorder', {
                    'videos': this.videos,
                })
                    .then((response) => {
                        console.log(response);
                        if(response) {
                            this.loading = false;
                            this.message = 'Updated successfully';
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.message = 'Something went wrong!';
                    });
            },
            // showOptions(e){
            //     let show = e.target.closest('.show-options');
            //     let hide = show.nextElementSibling;
            //     show.style.display = "none";
            //     hide.style.display = "flex";
            //     e.target.closest('.card-header').nextElementSibling.style.display = "flex";
            // },
            // hideOptions(e){
            //     let hide= e.target.closest('.hide-options');
            //     let show = hide.previousElementSibling;
            //     show.style.display = "flex";
            //     hide.style.display = "none";
            //     e.target.closest('.card-header').nextElementSibling.style.display = "none";
            // }
        },
    }
</script>
