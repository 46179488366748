<template>
    <div class="row">
        <div v-for="(expert, i) in exps" :key="i" class="col-12 col-sm-6 col-md-6 experts__col">
					<div  class="experts__item">
						<div class="experts__img">
							<!--272x402-->
							<img :src="expert.preview" alt="">
						</div>
						<div v-if="lang == 'ru'" class="experts__name"><span><i>{{expert.name_ru}}</i></span></div>
						<div v-else class="experts__name"><span><i>{{expert.name_ua}}</i></span></div>
						<div v-if="lang == 'ru'" class="experts__specialization"><span>{{expert.expertise_ru}}</span></div>
						<div v-else class="experts__specialization"><span>{{expert.expertise_ua}}</span></div>
						<div v-if="lang == 'ru'" class="experts__desk">
							<p><span>Опыт: </span>{{expert.exp_ru}}</p>
							<p><span>Больше всего в своей работе любит: </span>{{expert.favorite_job_ru}}</p>
							<p><span>Любимая культура: </span>{{expert.favorite_cult_ru}}</p>
							<p><span>Любимый совет: </span></p>
						</div>
                        <div v-else class="experts__desk">
                            <p><span>Досвід: </span>{{expert.exp_ua}}</p>
                            <p><span>Найбільше в своїй роботі любить: </span>{{expert.favorite_job_ua}}</p>
                            <p><span>Улюблена культура: </span>{{expert.favorite_cult_ua}}</p>
                            <p><span>Улюблена порада: </span></p>
                        </div>
						<div v-if="lang == 'ru'" class="experts__quote">{{expert.advice_ru}}</div>
						<div v-else class="experts__quote">{{expert.advice_ua}}</div>
					</div>
		</div>
    </div>
</template>d

<script>
    export default {
        name: "dynamic-experts",
        props: {
            experts: Array,
            locale: String,
        },
        data() {
            return {
                exps: this.experts,
                lang: this.locale
            }
        },
        mounted(){
            console.log(this.lang);
        }
    }
</script>

<style scoped>

</style>
