<template>
    <draggable class="drug-dealer"  tag="div" :list="pages" :group="{ name: 'pages' }"  @change="rebuildOrder" v-bind="dragOptions" >
        <div v-for="el in pages" :key="el.title" class="card" :class="{ inactive: !el.is_active }">
            <header class="card-header">
                <p class="card-header-title"> {{ el.title }}</p>
                <a href="#" class="card-header-icon show-options" aria-label="more options" v-on:click="showOptions">
                    <span class="icon"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
                </a>
                <a href="#" class="card-header-icon hide-options" aria-label="more options" v-on:click="hideOptions">
                    <span class="icon"><i class="fas fa-angle-up" aria-hidden="true"></i></span>
                </a>
                <div class="active-switch">
                    <input v-if ="el.is_active" :id="el.title" type="checkbox" :name="el.title" class="switch is-rounded is-success" checked="checked" @click="pageActivate(el)">
                    <input v-else :id="el.title" type="checkbox" :name="el.title" class="switch is-rounded is-success" @click="pageActivate(el)">
                    <label :for="el.title"></label>
                </div>
                <a :href="urlPrefix + '/page/' +  el.id  + '/edit'" class="card-footer-item"><i class="fas fa-pencil-alt"></i></a>
            </header>
            <footer class="card-footer">
                <div >

<!--                    <a :href="urlPrefix + '/page/' +  el.id  + '/edit'" class="card-footer-item red">Delete</a>-->
                </div>
                <nested-draggable :pages="el.pages"></nested-draggable>
            </footer>
        </div>
    </draggable>
</template>
<script>
  import draggable from 'vuedraggable';
  export default {
    props: {
      pages: {
        required: true,
      },
      drag: false,
    },
    components: {
      draggable
    },
      data(){
        return {
            urlPrefix: window.location.protocol + '//' + window.location.hostname + '/admin',
        }
      },
    mounted(){
       console.log('in nested:', this.pages);
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "pages",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    methods:{
      rebuildOrder(e){
          //console.log(e);
           console.log('pages', this.pages);
        // this.pages.forEach((e) => {
        //   e.sub.forEach((el) => {
        //     el.order = (e.sub.indexOf(el))
        //     el.parent_id = (e.parent);
        //     el.path = e.parent_path + '/' + el.title;
        //     if(el.parent_id == el.id){
        //       this.same = 'Element ' + el.title + ' cannot contain itself!';
        //       this.disabled = true;
        //     }
        //   });
        //
        // });
        this.pages.forEach((e) => {
          // console.log(e);
            e.order = (this.pages.indexOf(e))
            e.parent_id = (e.parent);
           // e.path = e.parent_path + '/' + el.title;
        });
      },
      showOptions(e){
        let show = e.target.closest('.show-options');
        let hide = show.nextElementSibling;
        show.style.display = "none";
        hide.style.display = "flex";
        e.target.closest('.card-header').nextElementSibling.style.display = "flex";
      },
      hideOptions(e){
        let hide= e.target.closest('.hide-options');
        let show = hide.previousElementSibling;
        show.style.display = "flex";
        hide.style.display = "none";
        e.target.closest('.card-header').nextElementSibling.style.display = "none";
      },
      pageActivate(el){
        (!el.is_active) ? el.is_active = 1 : el.is_active = 0;
      }

    },
    name: "nested-draggable",
  };
</script>
<style scoped>
    .dragArea {
        min-height: 50px;
        /*outline: 1px dashed;*/
    }
</style>
