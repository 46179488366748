<template>
    <div class="m-experts-druggable">
        <draggable v-model="experts" class="container" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="rebuildOrder">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="experts_wrapper" >
                <div v-for="element in experts" :key="element.id" class="card m-post m-experts">
                        <header class="card-header">
                            <div class="card-header-order">
                                {{ element.order }}
                            </div>
                            <p class="card-header-title">
                                {{ element.name_ru }}
                            </p>
                            <a href="#" class="card-header-icon show-options" aria-label="more options" @click="showOptions">
                                <span class="icon"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
                            </a>
                            <a href="#" class="card-header-icon hide-options" aria-label="more options" @click="hideOptions">
                                <span class="icon"><i class="fas fa-angle-up" aria-hidden="true"></i></span>
                            </a>
                            <a class="button is-link is-hidden-touch" :href="href + '/' + element.id + '/edit'">Редактировать</a>
                        </header>
                        <div class="card-content">
                            <div class="content">
                                <div class="l-name">
                                    <div class="name">{{ element.name_ru }}</div>
                                    <div class="name">{{ element.expertise_ru }}</div>
                                    <div class="name">{{ element.exp_ru }}</div>
                                </div>
                                <div class="l-img">
                                    <img :src="element.preview">
                                </div>
                                <div class="l-info">
                                    <div class="name">{{ element.favorite_cult_ru }}</div>
                                    <div class="name">{{ element.favorite_job_ru }}</div>
                                    <div class="name">{{ element.advice_ru }}</div>
                                    <div class="name">{{ element.work_position_ru }}</div>
                                </div>
                            </div>
                            <div class="">
                                <a class="button is-link is-hidden-desktop" :href="href + '/' + element.id + '/edit'">Редактировать</a>
                            </div>
                        </div>
                </div>
            </transition-group>
        </draggable>
                <button v-on:click.prevent="sendForm" :disabled="loading" class="button is-primary">Сохранить <span v-if="loading"><i class="fas fa-spinner fa-spin ml-2"></i></span></button>
        <div>{{ message }}</div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        props: {
            list: [Array,null],
            href: [String,null],
        },
        data() {
            return {
                experts: this.list,
                drag: false,
                loading: false,
                message: '',
            }
        },
        components: {
            draggable
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "videos",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            // sort() {
            //     this.experts = this.experts.sort((a, b) => a.order - b.order);
            // },
            rebuildOrder(e){
                console.log(this.experts);
                console.log(e);
                this.experts.forEach(el => el.order = (this.experts.indexOf(el)));
            },

            sendForm(){
                this.loading = true;
                axios.post('/admin/experts/order', {
                    'experts': this.experts,
                })
                    .then((response) => {
                        if(response) {
                            this.loading = false;
                            this.message = 'Updated successfully';
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.message = 'Something went wrong!';
                    });
            },
            showOptions(e){
                let show = e.target.closest('.show-options');
                let hide = show.nextElementSibling;
                show.style.display = "none";
                hide.style.display = "flex";
                e.target.closest('.card-header').nextElementSibling.style.display = "flex";
            },
            hideOptions(e){
                let hide= e.target.closest('.hide-options');
                let show = hide.previousElementSibling;
                show.style.display = "flex";
                hide.style.display = "none";
                e.target.closest('.card-header').nextElementSibling.style.display = "none";
            }
        },
    }
</script>
<style>

</style>
