<template>
    <section class="section-tags">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-tags__wrapp green">
                        <div class="section-tags__title">{{locale === 'ru' ? 'Популярные категории': 'Популярні категорії'}}</div>
                        <search data-id="blog"></search>
                        <div class="section-tags__items">
                            <a v-for="(tag, i) in myTags.values()" :key="i" :class="{checked: active[tag.id]}" :id="tag.id" v-on:click="handleClick">
                                <b>
                                    <i>
                                        <!--41x29px-->
                                        <img :src="tag.icon">
                                    </i>
                                </b>
                                <span onmousedown="return false" onselectstart="return false">{{locale === 'ru' ? tag.text: tag.text_ua}}</span>
                            </a>
                            <div class="section-tags__hide text-center d-md-none"><span>{{locale === 'ru' ? 'Свернуть категории' : 'Згорнути категорії'}}</span></div>
                            <div v-if="err.length">{{err}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import search from './Search'
    export default {
        name: 'tags-with-search',
        props: {
            tags: Array,
            locale: String
        },
        components: {
            search
        },
        data() {
            return {
                myTags: new Map(this.tags.map((tag) => [tag.id, tag])),
                err: '',
                active: this.tags.map(tag => false)
            }
        },
        created(){
            this.tags.forEach(tag => {
                this.$store.state.tagsLock[tag.id] = false
            })
            
        },
         methods:{
            handleClick(e){
                const result = this.$store.getters.isLocked[Number(e.currentTarget.id)]
                if (!result){
                    console.log(e.currentTarget.id)
                    e.preventDefault()
                    const id = e.currentTarget.id
                    this.active[id] = !this.active[id]
                    this.active = [...this.active]
                    if (this.active[id]) {
                        this.$store.dispatch('tagFilterSet', Number(id))
                    }else {
                        this.$store.dispatch('tagFilterUnset', Number(id))
                    }
                }
            }
        },
        computed: {
        }
    }
</script>
<style scoped>

</style>
