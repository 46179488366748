<template>
    <div class="section-tags__form">
							<form action="">
								<div class="input-wr">
									<label for="qq1">{{locale === 'ru' ? 'Найти статью' : 'Знайти статтю'}}</label>
									<input type="text" id="qq1" v-model="input"/>
									<button type="submit" @click.prevent="handleSubmit"></button>
								</div>
							</form>
						</div>
</template>

<script>
    export default {
        name: 'search',
        props: {
            dataId: String,
            locale: {
                required: false,
                type: String
            } 
        },
        created: {
            //* ЗДЕСЬ ТЫ ДОЛЖЕН УСТАНОВИТЬ ЛОКАЛЬ (ИМЕЕНО В ЭТОЙ СИТУАЦИИ ОНА УЖЕ УСТАНВАЛИВАЕТСЯ В ДРУГОМ КОМПОНЕНТЕЕ - Blogs, ПОЭТОМУ ЗДЕСЬ ЭТОГО МОЖНО НЕ ДЕЛАТЬ )
        },
        methods:{
            handleSubmit(e){
                this.$store.dispatch('getFilteredData', {dataId: this.dataId, input: this.input})
            }
        },
        data() {
            return {
                input: ''
            }
        },
        computed: {
            locale: function (){
                return this.$store.state.locale
            }
        }
    }
</script>
<style scoped>
.section-tags__form{
    z-index: 100;
}

</style>
