class Tag {
    constructor(tag) {
        if (!this._validated(tag)) {
            throw new Error("Tag:constructor - wrong tag format!")
        }
        this._tag = tag
    }
    _validated(tag) {
        if (!tag.hasOwnProperty('id') ||
            !tag.hasOwnProperty('length') ||
            !tag.hasOwnProperty('data') ||
            !tag.hasOwnProperty('allItemsSent')) {
            return false
        }
        return true
    }
    set tag(tag) {
        if (this._validated(tag)) {
            this._tag = tag
        } else {
            throw new Error("Tag:setter - wrong tag format!")
        }
    }
    get tag() {
        return this._tag
    }
    get length() {
        return this._tag.length
    }
    get active() {
        if (this._tag.active) return true
        return false
    }
    set active(active) {
        this._tag.active = active
    }
    get id() {
        return this._tag.id
    }
}
class TagsData {

    constructor(tags = []) {
        this._tags = []
        if (tags.length !== 0) {
            tags.forEach(tag => {
                this._tags.push(new Tag(tag))
            })
        }

    }
    set add(tag) {
        if (!this._findTag(tag.id))
            this._tags.push(new Tag(tag))
    }
    getById(id) {
        return this._findTag(i)
    }
    getByIndex(i) {
        return this._tags[i]
    }
    isActive(i) {
        let result = this._findTag(i)
        if (result) {
            if (result.active) return true
            return false
        } else {
            throw new Error("TagData:isActive - undefined index!")
        }
    }
    disable(i) {
        let result = this._findTag(i)
        if (result) {
            result.active = false
        } else {
            throw new Error("TagData:disable - undefined index!")
        }
    }
    _findTag(id) {
        let result = null
        this._tags.every(tag => {
            if (tag.id === id) {
                result = tag
                return false
            }
            return true
        })
        return result
    }
    remove(id) {
        let result = this._findTag(id)
        let index = this._tags.indexOf(result)
        const copy = {...this._tags[index] }
        delete this._tags[index]
        return copy._tag.data
    }
    findAvaibleTag() {
        let result = null
        this._tags.every(tag => {
            if (tag.tag.allItemsSent) {
                result = tag
                return false
            } else {
                return true
            }
        })
        return result
    }
    get length() {
        let length = 0
        this._tags.forEach(tag => {
            length += tag.length
        })
        return length
    }
    get isAllTagItemsSent() {
        let result = true
        this._tags.every(tag => {
            if (!tag.allItemsSent) {
                result = false
                return false
            } else {
                return true
            }
        })
        return result
    }
    get isSomeTagActive() {
        let result = false
        this._tags.every(tag => {
            if (tag.active) {
                result = true
                return false
            } else {
                return true
            }
        })
        return result
    }
    get listOfActiveItems() {
        let result = []
        this._tags.forEach(tag => {
            if (tag.active)
                result = [...result, ...tag.data]
        })
        return result
    }

}
export default TagsData